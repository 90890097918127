import Vue from "vue";
import VueRouter from "vue-router";
import RouletteView from "../views/home.vue";
import csaeView from "../views/caseShow.vue";
import aboutView from "../views/aboutUs.vue";
import detailsView from "../views/caseDetails.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: RouletteView,
  },
  {
    path: "/case",
    name: "case",
    component: csaeView,
  },
  {
    path: "/about",
    name: "about",
    component: aboutView,
  },
  {
    path: "/caseDetails",
    name: "caseDetails",
    component: detailsView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
