import axios from "axios";

const http = axios.create({
  baseURL: "https://adminguanwang.dudupaidui.com",
  timeout: 10000,
});

// 请求拦截
http.interceptors.request.use((config) => {
  config.headers = config.headers || {};
  //   config.headers['Origin']='http://vanws1.audiosroom.com'
  if (localStorage.getItem("token")) {
    config.headers.token = localStorage.getItem("token") || "";
  }
  return config;
});

// 响应拦截
http.interceptors.response.use(
  (res) => {
    const code = res.data.code;
    if (code == 200 || code == 0) {
      let data = res.data;
      data = data == undefined ? res.data : data;
      return data;
    }
    return Promise.reject(res); //失败
  },
  (err) => {
    let str = err.message;
    if (str.indexOf("timeout") != -1) {
      console.log("请求超时");
    }
    return Promise.reject(err);
  }
);

export default http;
