import http from "./axios";

// 获取轮播图
export function bannerList(params) {
  return http({
    url: "/api/banner/getList",
    method: "get",
    params
  });
}


// 获取案例展示分类
export function getClassify(params) {
  return http({
    url: "/api/cases/getClassify",
    method: "get",
    params
  });
}


// 获取案例列表
export function getClassifyList(params) {
  return http({
    url: "/api/cases/getList",
    method: "get",
    params
  });
}


// 获取案例列表
export function getCaseDetail(params) {
  return http({
    url: "/api/cases/detail",
    method: "get",
    params
  });
}

