<!-- 关于我们 -->
<template>
  <div class="app_container">
    <header>
      <!-- 导航条 -->
      <ComHeader></ComHeader>
      <div class="banner">
        <div class="tit">商城系统定制开发</div>
        <div class="know_btn _flex">
          <div class="text">立即咨询</div>
          <div class="icon _flex">
            <img src="../assets/homeImg/more.webp" alt="" />
          </div>
        </div>
      </div>
      <!-- 项目分类 -->
      <div class="class_wrap _flex">
        <div
          :class="['tabLi', tabId == v.id ? 'on' : '']"
          v-for="(v, i) in classTab"
          :key="i"
          @click="changeTab(v)"
        >
          {{ v.name }}
        </div>
      </div>
    </header>
    <main>
      <!-- 面包屑 -->
      <div class="crumbs _flex">
        <div class="text">网站首页</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text">关于我们</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text now">{{ crumbsName }}</div>
      </div>

      <!-- 内容介绍 -->
      <div class="us_content">
        <div class="title">{{ crumbsName }}</div>

        <!-- 公司简介 -->
        <div class="content_wrap" v-if="tabId==1">
          <!-- 简介内容 -->
          <div class="intro">
            <p>
              汇聚智慧，砥砺前行，共创美好明天！我们深知我们的代码承载着每一个客户的耀与梦想，365天7*24小时的售后支撑是我们最引以为傲的资本。畅冉企业信息咨询(西安)有限公司。我司成功帮助多家知名互联网公司获得多轮融资。专注于手机APP定制开限公司是由多位互联网龙头企业技术大牛于2023年创办，高端网站定制、企业网站建设、手机移动网站建设、应用系统开发等为企业提供全球化互联网解决方案。公司自创立以来，已成功为数百余家企事业单位、政府机关提供过高端互联网服务。
            </p>
            <p>
              我们秉承“为合作伙伴创造价值”的核心价值观，以“诚实、宽容、创服务”为精神。
            </p>
            <p>欢迎全国各地合作伙伴来公司洽谈，携手共进，共赢未来!</p>
          </div>

          <div class="num_wrap _flex">
            <div class="num_li">
              <div class="num">4<span>年</span></div>
              <div class="text">互联网前沿技术经验</div>
            </div>
            <div class="num_li">
              <div class="num">200<span>＋</span></div>
              <div class="text">商标、专利、软件著作权</div>
            </div>
            <div class="num_li">
              <div class="num">360<span>行</span></div>
              <div class="text">全行业覆盖</div>
            </div>
            <div class="num_li">
              <div class="num">600<span>＋</span></div>
              <div class="text">品牌公司的认可</div>
            </div>
            <div class="num_li">
              <div class="num">1000<span>行</span></div>
              <div class="text">企业客户的选择</div>
            </div>
          </div>
        </div>

        <!-- 企业文化 -->
        <div class="content_wrap" v-if="tabId==2">
          <div class="enterprise _flex">
            <div class="enter">
              <div class="icon">
                <img src="../assets/homeImg/like.webp" alt="" />
              </div>
              <div class="text">企业愿景</div>
            </div>
            <div class="enter">
              <div class="icon">
                <img src="../assets/homeImg/file.webp" alt="" />
              </div>
              <div class="text">企业使命</div>
            </div>
            <div class="enter">
              <div class="icon">
                <img src="../assets/homeImg/ours.webp" alt="" />
              </div>
              <div class="text">企业精神</div>
            </div>
            <div class="enter _last">
              <div class="icon">
                <img src="../assets/homeImg/value.webp" alt="" />
              </div>
              <div class="text">企业价值观</div>
            </div>
          </div>

          <div class="enter_info _flex">
            <div class="info_li">
              <div class="tit">企业愿景</div>
              <div class="text">
                <p>致力于帮助企业</p>
                <p>实现互联网+</p>
              </div>
            </div>
            <div class="info_li">
              <div class="tit">企业使命</div>
              <div class="text">成就自己，为客户创造价值</div>
            </div>
            <div class="info_li">
              <div class="tit">企业精神</div>
              <div class="text">
                <p>用真诚服务客户，</p>
                <p>用品质成就自己</p>
              </div>
            </div>
            <div class="info_li">
              <div class="tit">企业口号</div>
              <div class="text">
                <p>汇聚智慧，砥砺前行，</p>
                <p>共创美好明天！</p>
              </div>
            </div>
            <div class="info_li _last">
              <div class="tit">企业价值观</div>
              <div class="text">诚信为先，服务至上</div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <ComFooter></ComFooter>
    </footer>
  </div>
</template>

<script>
import ComHeader from "@/components/comHeader.vue";
import ComFooter from "@/components/comFooter.vue";
export default {
  components: {
    ComHeader,
    ComFooter,
  },
  data() {
    return {
      tabId: 1,
      classTab: [
        { id: 1, name: "公司简介" },
        { id: 2, name: "企业文化" },
        { id: 3, name: "荣誉资质" },
        { id: 4, name: "合作伙伴" },
      ],
      crumbsName: "公司简介", //面包屑展示
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 切换导航
    changeTab(v){
      this.tabId=v.id
      this.crumbsName=v.name
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/comStyle.scss";
.app_container {
  header {
    .banner {
      @include _headStyle;
    }

    //   项目分类
    .class_wrap {
      @include _headClass;
    }
  }
  main {
    background: #f0f0f0;
    padding: 0 126px;
    padding-bottom: 80px;

    // 面包屑
    .crumbs {
      @include _crumbsStyle;
    }

    .us_content {
      padding: 80px 84px;
      background: #ffffff;
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 43px;
        color: #151618;
        text-align: center;
      }
      //   简介内容
      .intro {
        background: #f7f9ff;
        padding: 56px;
        margin-top: 60px;
        p {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 27px;
          color: #525252;
          line-height: 43px;
          margin-bottom: 60px;
        }
      }

      .num_wrap {
        justify-content: space-between;
        margin-top: 64px;
        .num_li {
          font-family: PingFang SC, PingFang SC;
          text-align: center;
          .num {
            font-weight: 600;
            font-size: 64px;
            color: #7095f8;
            span {
              font-size: 21px;
            }
          }
          .text {
            font-weight: 600;
            font-size: 27px;
            color: #585858;
          }
        }
      }

      //   企业文化
      .enterprise {
        background: #f7f9ff;
        border: 3px solid #dedede;
        box-sizing: border-box;
        margin-top: 60px;
        justify-content: space-between;
        .enter {
          flex: 1;
          border-right: 3px solid #dedede;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 114px;
          padding-bottom: 94px;
          box-sizing: border-box;
          .icon {
            width: 80px;
            height: 80px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 32px;
            color: #585858;
            margin-top: 70px;
          }
        }
        ._last {
          border-right: 0;
        }
      }

      .enter_info {
        padding: 48px 82px;
        .info_li {
          flex: 1;
          min-height: 368px;
          font-family: PingFang SC, PingFang SC;
          background: #7095f8;
          border-right: 3px solid #dedede;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 135px;
          .tit {
            font-weight: 600;
            font-size: 32px;
            color: #ffffff;
          }
          .text {
            margin-top: 36px;
            font-weight: 400;
            font-size: 21px;
            color: #ffffff;
            text-align: center;
          }
        }
        ._last {
          border-right: 0;
        }
      }
    }
  }
}
</style>
