<!-- 底部组件 -->
<template>
  <div class="app_container">
    <!-- icon分类 -->
    <div class="top_wrap _flex">
      <div class="icon_wrap _flex" v-for="(v, i) in topData" :key="i">
        <div class="icon">
          <img :src="v.icon" alt="" />
        </div>
        <div class="text">{{ v.text }}</div>
      </div>
    </div>

    <!-- 服务列表 -->
    <div class="serve_wrap _flex">
      <div class="_l _flex">
        <div class="se_li">
          <div class="tit">案例展示</div>
          <p>语音聊天</p>
          <p>视频直播</p>
          <p>商城系统定制开发</p>
          <p>微信公众号小程序</p>
          <p>网站定制开发</p>
          <p>app定制开发</p>
        </div>
        <div class="se_li">
          <div class="tit">关于我们</div>
          <p>公司简介</p>
          <p>企业文化</p>
          <p>荣誉资质</p>
          <p>合作伙伴</p>
        </div>
        <div class="se_li">
          <div class="tit">新闻中心</div>
          <p>APP开发</p>
          <p>微信开发</p>
          <p>网站开发</p>
          <p>行业资讯</p>
          <p>公司新闻</p>
        </div>
        <div class="se_li">
          <div class="tit">联系我们！</div>
          <p>联系方式</p>
          <p>在线留言</p>
          <p>法律声明</p>
          <p>微信公众号小程序</p>
          <p>隐私政策</p>
          <p>网站地图</p>
        </div>
      </div>
      <div class="_r">
        <div class="call_wrap _flex">
          <div class="icon">
            <img src="../assets/homeImg/call.webp" alt="" />
          </div>
          <div class="num">15598241114 <span>(7*24h)</span></div>
        </div>
        <p>地 址:陕西省西安市高新区锦业路1号都市之 门C座1幢1单元10511室</p>
        <p>E-mail: 792646054@qq.com</p>
        <p>网址:https://www.dudupaidui.com/#/</p>
        <div class="pic_wrap _flex">
          <div class="icon">
            <img src="../assets/homeImg/wechat.webp" alt="" />
          </div>
          <div class="icon">
            <img src="../assets/homeImg/qq.webp" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 链接 -->
    <div class="link_wrap">
      <div class="link_con _flex">
        <div class="tit">友情链接：</div>
        <p>杭州软件开发</p>
        <p>海外仓对接系统</p>
        <p>河南招标网</p>
        <p>成都设计公司</p>
        <p>直销软件开发</p>
        <p>人才网站开发</p>
        <p>成都品牌设计公司</p>
        <p>商标分类</p>
        <p>品牌设计公司</p>
      </div>
      <div class="link_con _flex">
        <p>上海SEO</p>
        <p>高端网站建设</p>
        <p>空运货代</p>
        <p>航空运输公司</p>
        <p>郑州软件开发</p>
        <p>多多出评软件</p>
      </div>
    </div>

    <!-- 公司信息 -->
    <div class="info_wrap _flex">
      <div class="_l">
        <p>
          Copyright@2014-2024 畅冉企业信息咨询（西安）有限公司 All Rights
          Reserved.豫网文(2021)1180-044号 豫ICP备16007664号-1
        </p>
        <p>Processed in2.526512 second(s)</p>
      </div>
      <div class="_r">在线留言丨联系我们丨站点地图</div>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      topData: [
        {
          icon: require("../assets/homeImg/footIcon1.webp"),
          text: "7x24小时售后支持",
        },
        {
          icon: require("../assets/homeImg/footIcon2.webp"),
          text: "跨浏览器兼容",
        },
        {
          icon: require("../assets/homeImg/footIcon3.webp"),
          text: "符合W3C标准及规范",
        },
        {
          icon: require("../assets/homeImg/footIcon4.webp"),
          text: "100%纯手工编码",
        },
        {
          icon: require("../assets/homeImg/footIcon5.webp"),
          text: "免费ICP备案服务",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.app_container {
  background: #252a36;
  padding: 48px 204px 28px 204px;
  .top_wrap {
    padding: 46px 0;
    justify-content: space-between;
    border-bottom: 1px solid #474e5d;
    .icon_wrap {
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 21px;
        color: #ffffff;
      }
    }
  }

  // 服务列表
  .serve_wrap {
    padding: 43px 0 64px 0;
    border-bottom: 1px solid #474e5d;

    ._l {
      flex: 1;
      align-items: flex-start;
      padding-right: 338px;
      justify-content: space-between;
      border-right: 1px solid #474e5d;
      .se_li {
        font-family: PingFang SC, PingFang SC;
        .tit {
          font-weight: 600;
          font-size: 21px;
          color: #ffffff;
        }
        p {
          font-weight: 400;
          font-size: 19px;
          color: #d6d6d6;
          margin-top: 23px;
        }
      }
    }
    ._r {
      padding-left: 84px;
      padding-right: 28px;
      .call_wrap {
        margin-bottom: 27px;
        .icon {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .num {
          font-family: DIN, DIN;
          font-weight: 700;
          font-size: 37px;
          color: #ffffff;
          span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 19px;
            color: #d6d6d6;
          }
        }
      }
      p {
        max-width: 355px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 19px;
        color: #d6d6d6;
        line-height: 47px;
      }
      .pic_wrap {
        margin-top: 18px;
        .icon {
          width: 54px;
          height: 54px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon:nth-child(1) {
          margin-right: 32px;
        }
      }
    }
  }

  // 链接
  .link_wrap {
    padding: 32px 0 18px 0;
    border-bottom: 1px solid #474e5d;

    .link_con {
      font-family: PingFang SC, PingFang SC;
      .tit {
        font-weight: 600;
        font-size: 19px;
        color: #ffffff;
      }
      p {
        font-weight: 400;
        font-size: 19px;
        color: #d6d6d6;
        padding: 0 20px;
      }
    }
    .link_con:nth-child(2) {
      margin-left: 94px;
      margin-top: 20px;
    }
  }

  // 公司信息
  .info_wrap {
    padding-top: 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 19px;
    color: #d6d6d6;
    align-items: flex-start;
    justify-content: space-between;
  }
}
</style>
