<!-- 案例详情 -->
<template>
  <div class="app_container">
    <header>
      <ComHeader></ComHeader>
      <div class="empty"></div>
    </header>
    <main>
      <!-- 面包屑 -->
      <div class="crumbs _flex">
        <div class="text">网站首页</div>
        <i class="el-icon-arrow-right"></i>
        <div class="text">案例展示</div>
        <!-- <i class="el-icon-arrow-right"></i> -->
        <!-- <div class="text now">语音聊天</div> -->
      </div>

      <!-- 详情 -->
      <div class="detail_wrap _flex">
        <div class="detail_l">
          <div class="tit">
            <div class="text">{{ detailData.name }}</div>
          </div>
          <div class="mes data">
            <div class="_l">发布时间：</div>
            <div class="_r">{{ timeTotal(detailData.createtime, false) }}</div>
          </div>
          <div class="mes author active">
            <div class="_l">作者：</div>
            <div class="_r">{{ detailData.author }}</div>
          </div>
          <div class="mes source">
            <div class="_l">来源：</div>
            <div class="_r">{{ detailData.source }}</div>
          </div>
          <div class="mes source active">
            <div class="_l">浏览量：</div>
            <div class="_r">{{ detailData.page_view }}</div>
          </div>
          <div class="mes source">
            <div class="_l">描述：</div>
            <div class="_r">{{ detailData.description }}</div>
          </div>
          <!-- 咨询 -->
          <div class="btn_wrap">
            <div class="btn">立即咨询</div>
          </div>
        </div>
        <div class="detail_r">
          <img :src="detailData.cases_image" alt="" />
        </div>
      </div>
    </main>
    <footer>
      <ComFooter></ComFooter>
    </footer>
  </div>
</template>

<script>
import ComFooter from "@/components/comFooter.vue";
import ComHeader from "@/components/comHeader.vue";
import { getCaseDetail } from "../request/api";
import { timeTotal } from "@/utils";
export default {
  components: {
    ComFooter,
    ComHeader,
  },
  data() {
    return {
      timeTotal,
      paramsId: null,
      detailData: {}, //详情信息
    };
  },
  watch: {
    // 监听路由参
    $route: {
      handler(newV, _oldV) {
        if (newV) {
          this.paramsId = newV.query.id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  created() {},
  mounted() {
    // 获取案例详情
    getCaseDetail({
      id: this.paramsId,
    }).then((res) => {
      // console.log(res);
      this.detailData = res.data;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../style/comStyle.scss";
.app_container {
  .empty {
    background: #222735;
    padding-top: 124px;
  }
  main {
    background: #f0f0f0;
    padding: 0 126px;
    padding-bottom: 105px;
    // 面包屑
    .crumbs {
      @include _crumbsStyle;
    }

    // 详情
    .detail_wrap {
      padding: 80px 53px 84px 108px;
      background: #ffffff;
      .detail_l {
        flex: 1;
        .tit {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 28px;
          color: #525252;
          border-bottom: 3px solid #dedede;
          padding-bottom: 25px;
          margin-bottom: 18px;
          .text {
            border-left: 11px solid #7095f8;
            padding-left: 27px;
          }
        }

        // 详情信息
        .mes {
          padding: 9px;
          padding-left: 37px;
          display: flex;
          font-family: PingFang SC, PingFang SC;
          ._l {
            font-weight: 600;
            font-size: 18px;
            color: #525252;
            min-width: 90px;
            text-align: right;
          }
          ._r {
            font-weight: 400;
            font-size: 18px;
            color: #878686;
            margin-left: 25px;
            flex: 1;
          }
        }
        .active {
          background: #f7f9ff;
        }

        // 立即咨询
        .btn_wrap {
          border-top: 3px solid #dedede;
          margin-top: 18px;
          padding: 27px 359px 0 37px;
          .btn {
            padding: 9px 60px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            background: #de796b;
          }
        }
      }
      .detail_r {
        width: 768px;
        height: 482px;
        background: #d8d8d8;
        margin-left: 140px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
