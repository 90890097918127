<!-- 产品官网 -->
<template>
  <div class="app_container">
    <header>
      <!-- 导航条 -->
      <ComHeader></ComHeader>

      <!-- 轮播内容 -->
      <div class="head_wrap">
        <div class="banner">
          <el-carousel :autoplay="false" indicator-position="none">
            <el-carousel-item v-for="(v, i) in bannerLi" :key="i">
              <img :src="v.image" alt="" @click="handleBanner(v)" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 分类 -->
      <div class="head_foot">
        <div class="item">
          <div class="li_tit">APP开发</div>
          <div class="line"></div>
          <div class="intro">专门用于手机APP开发</div>
        </div>
        <div class="item">
          <div class="li_tit">响应式网站</div>
          <div class="line"></div>
          <div class="intro">一个网站能够兼容多个终端</div>
        </div>
        <div class="item">
          <div class="li_tit">小程序</div>
          <div class="line"></div>
          <div class="intro">用户扫一扫或搜一下即可打开</div>
        </div>
        <div class="item">
          <div class="li_tit">视频直播</div>
          <div class="line"></div>
          <div class="intro">为企业提供视频直播服务</div>
        </div>
        <div class="item _last">
          <div class="li_tit">版权登记</div>
          <div class="line"></div>
          <div class="intro">为企业提供知识产权保护服务</div>
        </div>
      </div>
    </header>

    <main>
      <div class="topic">案例展示</div>
      <!-- 案例分类 -->
      <div class="classify_wrap _flex">
        <div
          :class="['_class', proId == v.id ? '_on' : '']"
          v-for="(v, i) in projectLi"
          :key="i"
          @click="changePro(v.id)"
        >
          {{ v.name }}
        </div>
      </div>

      <!-- 案例列表 -->
      <div class="classify_li _flex">
        <div
          class="list_wrap"
          v-for="(item, ind) in caseList"
          :key="ind"
          @click="handleToDetails(item.id)"
        >
          <div class="li_pic">
            <img :src="item.cases_image" alt="" />
          </div>
          <div class="li_name">{{ item.name }}</div>
          <div class="li_message _flex">
            <div class="mes_time _flex">
              <div class="icon _flex">
                <img src="../assets/homeImg/wait.webp" alt="" />
              </div>
              <div class="data">
                {{ timeTotal(item.createtime, true) }}
              </div>
            </div>
            <div class="label_wrap _flex">
              <div class="label _flex">
                <div class="icon">
                  <img src="../assets/homeImg/flag.webp" alt="" />
                </div>
                <div class="text _flex">
                  <span>{{ item.categories[0].name }}</span>
                  <span v-if="item.categories.length > 1">…</span>
                </div>
                <!-- <div class="label_tab _flex"> -->
                <!-- <div class="text" v-for="(v, i) in item.categories" :key="i">
                    <span>{{ v.name }}</span>
                    <span>{{
                      i === item.categories.length - 1 ? "" : "."
                    }}</span>
                  </div> -->
                <!-- </div> -->
              </div>
              <div class="label _flex">
                <div class="icon">
                  <img src="../assets/homeImg/canvas.webp" alt="" />
                </div>
                <!-- <div class="text" v-for="(v, i) in item.colours" :key="i">
                  <span>{{ v.name }}</span>
                  <span>{{ i === item.colours.length - 1 ? "" : "." }}</span>
                </div> -->
                <div class="text _flex">
                  <span>{{ item.colours[0].name }}</span>
                  <span v-if="item.colours.length > 1">…</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 查看更多 -->
      <div class="more_btn _flex">
        <div class="wrap _flex" @click="goPath('/case')">
          <div class="text">查看更多</div>
          <div class="icon">
            <img src="../assets/homeImg/more.webp" alt="" />
          </div>
        </div>
      </div>

      <!-- 公司简介 -->
      <div class="company_profile">
        <img src="../assets/homeImg/companyPro.webp" alt="" />
      </div>

      <!-- <div class="topic">新闻中心</div> -->

      <!-- 新闻中心分类 
      <div class="classify_wrap _flex">
        <div class="_class _on">公司新闻</div>
        <div class="_class">视频直播</div>
        <div class="_class">公司新闻</div>
        <div class="_class">视频直播</div>
        <div class="_class">公司新闻</div>
      </div>-->

      <!-- 新闻中心 
      <div class="news_wrap">新闻中心</div>-->

      <!-- 查看更多 
      <div class="more_btn _flex">
        <div class="wrap _flex">
          <div class="text">查看更多</div>
          <div class="icon">
            <img src="../assets/homeImg/more.webp" alt="" />
          </div>
        </div>
      </div>-->
    </main>

    <footer>
      <ComFooter></ComFooter>
    </footer>
  </div>
</template>

<script>
import ComFooter from "@/components/comFooter.vue";
import ComHeader from "@/components/comHeader.vue";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { bannerList, getClassify, getClassifyList } from "../request/api";
import { timeTotal } from "../utils/index";
export default {
  components: {
    Swiper,
    SwiperSlide,
    ComFooter,
    ComHeader,
  },
  data() {
    return {
      timeTotal,
      input: "",
      projectLi: [], //项目分类
      proId: null, //项目分类id
      caseList: [], //案例列表
      bannerLi: [
        {
          image: require("../assets/homeImg/bannerO.webp"),
          url: "/",
        },
        {
          image: require("../assets/homeImg/bannerT.webp"),
          url: "/",
        },
        {
          image: require("../assets/homeImg/bannerThr.webp"),
          url: "/",
        },
        {
          image: require("../assets/homeImg/bannerFour.webp"),
          url: "/",
        },
        {
          image: require("../assets/homeImg/bannerF.webp"),
          url: "/",
        },
      ], //轮播图列表
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      // 获取案例展示分类
      await getClassify().then((res) => {
        this.projectLi = res.data.project; //项目分类
        this.proId = res.data.project[0].id;
      });
      await getClassifyList({
        project_id: this.proId,
      }).then((res) => {
        this.caseList = res.data.cases;
      });
    },

    // // 获取banner列表
    // getBannerData() {
    //   bannerList().then((res) => {
    //     this.bannerLi = res.data;
    //   });
    // },

    // banner详情
    handleBanner(val) {
      console.log(val, "val");
    },

    // 切换项目分类
    changePro(ind) {
      this.proId = ind;
      this.getCaseData();
    },

    // 跳转路由
    goPath(url) {
      this.$router.push(url);
    },

    // 获取案例列表
    getCaseData() {
      getClassifyList({
        project_id: this.proId,
      }).then((res) => {
        this.caseList = res.data.cases;
      });
    },

    // 跳转案例详情
    handleToDetails(id) {
      this.$router.push({
        path: "/caseDetails",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.app_container {
  header {
    width: 100%;
    position: relative;
    // 轮播内容
    .head_wrap {
      .banner {
        width: 100%;
        height: 800px;
        ::v-deep .el-carousel {
          width: 100%;
          height: 100%;
        }
        ::v-deep .el-carousel__container {
          width: 100%;
          height: 100% !important;
        }
        // 指示器
        ::v-deep .el-carousel__button {
          width: 13px;
          height: 13px;
          background: #d8d8d8;
          border-radius: 50%;
        }
        // 指示器激活按钮
        ::v-deep .is-active .el-carousel__button {
          width: 40px;
          height: 13px;
          background: #d8d8d8;
          border-radius: 85px 85px 85px 85px;
        }
        ::v-deep .el-carousel__indicator--horizontal {
          padding: 12px 8px;
          // padding-bottom: 100px;
        }
        // 左右按钮
        ::v-deep .el-carousel__arrow {
          background: rgba(255, 255, 255, 0);
          font-size: 40px;
          display: inline-block !important;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    // 头部=>底部分类
    .head_foot {
      position: absolute;
      z-index: 9;
      left: 0;
      bottom: 0;
      box-sizing: border-box;
      width: 100%;
      height: fit-content;
      padding: 0 214px;
      background: rgba(216, 216, 216, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      // 分类列表
      .item {
        width: 20%;
        padding: 30px 20px;
        border-left: 3px solid #818181;
        font-family: PingFang SC, PingFang SC;
        color: #ffffff;

        .li_tit {
          font-weight: 600;
          font-size: 21px;
        }
        .line {
          margin: 16px 0;
          width: 45px;
          height: 3px;
          background: #7095f8;
        }
        .intro {
          font-weight: 400;
          font-size: 16px;
        }
      }
      ._last {
        border-right: 3px solid #818181;
      }
    }
  }

  // main
  main {
    .topic {
      padding-top: 70px;
      padding-bottom: 50px;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 43px;
      color: #151618;
    }
    // 分类tab
    .classify_wrap {
      height: 100px;
      margin: 0 200px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 3px solid #dedede;
      ._class {
        flex: 1;
        height: 100%;
        padding: 0 80px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 27px;
        color: #151618;
        cursor: pointer;
      }
      ._on {
        border-bottom: 5px solid #7095f8;
        box-sizing: content-box;
        color: #7095f8;
      }
    }
    // 案例列表
    .classify_li {
      padding: 40px 213px 50px 213px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .list_wrap {
        background: url("../assets/homeImg/classLiBg.webp") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 30px;
        box-shadow: 0px 11px 27px 0px rgba(0, 0, 0, 0.1);
        padding-bottom: 44px;
        .li_pic {
          width: 470px;
          height: 344px;
          padding-top: 30px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .li_name {
          margin: 30px 0;
          border-left: 5px solid #7095f8;
          padding-left: 25px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 21px;
          color: #151618;
        }
        .li_message {
          padding: 16px 27px;
          background: #f1f1f1;
          justify-content: space-between;
          .mes_time {
            .icon {
              margin-right: 10px;
            }
            .data {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #7c7c7c;
            }
          }
          .label {
            padding: 8px 6px;
            border: 1px solid #7095f8;
            border-radius: 4px;
            max-width: 112px;
            max-height: 32px;
            overflow-x: hidden;

            .text {
              align-items: center;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #7095f8;
              margin-left: 6px;
            }
          }
          .label:nth-child(1) {
            margin-right: 7px;
          }
        }
      }
    }

    // 查看更多
    .more_btn {
      justify-content: center;
      .wrap {
        padding: 16px 50px;
        background: #7095f8;
        border-radius: 379px 379px 379px 379px;
        cursor: pointer;
        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 27px;
          color: #ffffff;
        }
        .icon {
          width: 24px;
          height: 21px;
          margin-left: 14px;
        }
      }
    }

    // 公司简介
    .company_profile {
      margin-top: 80px;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // 新闻中心
    .news_wrap {
      padding: 55px 200px 80px 200px;
    }
  }

  // 底部
}
</style>
